<template>
  <div>
    <validation-observer ref="formValidationConsultaBeneficio">
      <b-form @submit.prevent="onSubmit">
        <b-card>
          <div>
            <b-row>
              <b-col md="12">
                <b-table
                  id="tblBeneficio"
                  ref="tableConsultaBenef"
                  :items="tableDataSolicitudesBeneficio"
                  :busy="isBusyBuscando"
                  :fields="fieldsSolicitudesBeneficio"
                  outlined
                  small
                  responsive
                  show-empty
                  empty-text="No se encontraron resultados para esta búsqueda"
                  @row-dblclicked="dobleClik"
                >
                  <template v-slot:table-busy>
                    <div class="text-center">
                      <b-spinner />
                    </div>
                  </template>
                  <template #cell(usuario)="row">
                    <small>
                      {{ buildNombre(row.item.usuario) }}
                    </small>
                  </template>
                  <template #cell(fechaSolicitud)="row">
                    <small>
                      {{ formatDateTable(row.item.fechaSolicitud, 'DD/MM/YYYY') }}
                    </small>
                  </template>
                  <template #cell(estado)="row">
                    <small v-b-tooltip.hover="detalleFechaEstado(row.item)">
                      {{ row.item.solicitudEstado.nombreCustom }}
                    </small>
                  </template>
                  <template #cell(actions)="row">
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <b-button
                        v-if="usarDetalleIntegrado"
                        size="sm"
                        @click="row.toggleDetails"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                    </div>
                  </template>
                  <template #row-details="row">
                    <detalle-beneficio
                      v-if="isTipoConProducto(row.item.beneficio.beneficioTipo.codigo) || isTipoConProductoPerpetuo(row.item.beneficio.beneficioTipo.codigo) ||
                        isTipoConProductoPaniales(row.item.beneficio.beneficioTipo.codigo)"
                      :item-index="row.index"
                      :detalle-solicitud="row.item"
                      @changeItemIndex="changeItemIndex"
                    />
                    <detalle-beneficio-quincho
                      v-if="isTipoQuincho(row.item.beneficio.beneficioTipo.codigo)"
                      :item-index="row.index"
                      :detalle-solicitud="row.item"
                      @changeItemIndex="changeItemIndex"
                    />
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <div class="align-self-end ml-auto">
              <b-col md="12">
                <b-input-group>

                  <b-input-group-append>
                    <!--v-b-tooltip.hover="'Seleccione la cantidad de resultados por página'"-->
                    <b-form-select
                      v-model="pageBeneficio.size"
                      :clearable="false"
                      title="Seleccione la cantidad de resultados por página"
                      label="title"
                      :options="optionsPaginationSize"
                      size="sm"
                      :disabled="isBusyBuscando"
                      @change="validationFormUsuario()"
                    />
                  </b-input-group-append>

                  <b-pagination
                    id="pgnbENEFICIO"
                    v-model="currentPage"
                    :total-rows="pageTotalElements"
                    :per-page="pageSize"
                    :disabled="isBusyBuscando"
                    @change="changePaginate"
                  />
                </b-input-group>
              </b-col>
            </div>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
// import BCardCode from '@core/components/b-card-code'
import { mapGetters } from 'vuex'
import { consultaSolicitudResource } from '@/services/consultaSolicitudResource'
import { reporteResource } from '@/services/reporteResource'
import { saveAs } from 'file-saver'
import DetalleBeneficio from './DetalleBeneficio.vue'
import DetalleBeneficioQuincho from './DetalleBeneficioQuincho.vue'
import {
  BENE_TIPO_CON_PRODUCTO,
  BENE_TIPO_CON_PRODUCTO_PERPETUO,
  BENE_TIPO_QUINCHO,
  BENE_TIPO_CON_PRODUCTO_PANIALES,
} from '@/utils/codigoTipoBeneConst'

export default {
  components: {
    DetalleBeneficio,
    DetalleBeneficioQuincho,
  },
  props: {
    afiliadoDto: {
      type: Object,
      required: false,
      default: null,
    },
    idAfiliado: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      usarDetalleIntegrado: true,
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      filtro: null,
      pageBeneficio: {
        page: 0,
        size: 7,
      },
      BENE_TIPO_CON_PRODUCTO,
      BENE_TIPO_CON_PRODUCTO_PERPETUO,
      BENE_TIPO_QUINCHO,
      BENE_TIPO_CON_PRODUCTO_PANIALES,
      consultaBeneSolicitudFiltroDto: {
        usuarioDelegacionId: null,
        solicitanteCuil: null,
        beneficioId: null,
        solicitudEstadoId: null,
        fechaDesdeComponent: null,
        fechaDesde: null,
        fechaHastaComponent: null,
        feachaHasta: null,
        pageNumber: null,
        pageSize: null,
        sort: null,
        order: null,
      },
      nameModulo: 'CONSULTA_BENEFICIO',
      isBusyBuscando: false,
      tableDataSolicitudesBeneficio: [],
      solicitudEstadoDto: [],
      optionsPaginationSize: [5, 7, 10, 15, 20, 25, 30],
      fieldsSolicitudesBeneficio: [
        {
          key: 'id', label: 'Id',
        },
        {
          key: 'beneficio.nombre', label: 'beneficio',
        },
        {
          key: 'beneficiarioNombre', label: 'beneficiario',
        },
        {
          key: 'fechaSolicitud', label: 'fecha solicitud',
        },
        {
          key: 'estado', label: 'estado',
        },
        { key: 'actions', label: 'Acciones' },
      ],
    }
  },

  computed: {
    contieneEntregador() {
      let contieneEntregador = false
      const someEntregador = this.currentUser.roles.filter(rol => rol.codigo === 'ENTREGADOR_BENEFICIOS')
      if (someEntregador.length > 0) {
        contieneEntregador = true
      }
      return contieneEntregador
    },
    contieneAfiliado() {
      let contieneAfiliado = false
      const someAfiliado = this.currentUser.roles.filter(rol => rol.codigo === 'AFILIADO')
      if (someAfiliado.length > 0) {
        contieneAfiliado = true
      }
      return contieneAfiliado
    },
    contieneGestor() {
      let contieneEntregador = false
      const someGestor = this.currentUser.roles.filter(rol => rol.codigo === 'GESTOR_BENEFICIOS')
      if (someGestor.length > 0) {
        contieneEntregador = true
      }
      return contieneEntregador
    },
    isEntregadorButNotGestor() {
      let isEntregadorButNotGestor = false
      const someEntregador = this.currentUser.roles.filter(rol => rol.codigo === 'ENTREGADOR_BENEFICIOS')
      const someGestor = this.currentUser.roles.filter(rol => rol.codigo === 'GESTOR_BENEFICIOS')
      if (someEntregador.length > 0 && someGestor.length === 0) {
        // Si tiene el rol afiliado y este es el unico rol presente en el array Roles, entonces es AFILIADO unicamente.
        isEntregadorButNotGestor = true
      }
      return isEntregadorButNotGestor
    },
    isAfiliadoOnly() {
      let afiliadoOnly = false
      const items = this.currentUser.roles.filter(rol => rol.codigo === 'AFILIADO')
      if (this.currentUser.roles.length === 1 && items.length > 0) {
        // Si tiene el rol afiliado y este es el unico rol presente en el array Roles, entonces es AFILIADO unicamente.
        afiliadoOnly = true
      }
      return afiliadoOnly
    },
    maxDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)
      return maxDate
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        currentUser: 'getCurrentUser',
      },
    ),
  },
  created() {
    this.getSolicitudesFilter()
  },
  methods: {
    isTipoConProducto(codigo) {
      if (codigo === BENE_TIPO_CON_PRODUCTO) {
        return true
      }
      return false
    },
    isTipoConProductoPerpetuo(codigo) {
      if (codigo === BENE_TIPO_CON_PRODUCTO_PERPETUO) {
        return true
      }
      return false
    },
    isTipoConProductoPaniales(codigo) {
      if (codigo === BENE_TIPO_CON_PRODUCTO_PANIALES) {
        return true
      }
      return false
    },
    isTipoQuincho(codigo) {
      if (codigo === BENE_TIPO_QUINCHO) {
        return true
      }
      return false
    },
    // eslint-disable-next-line no-unused-vars
    dobleClik(record, index, mouseEvent) {
      this.tableDataSolicitudesBeneficio.forEach(item => {
        const objIndex = this.tableDataSolicitudesBeneficio.findIndex((obj => obj.id === item.id))
        if (objIndex !== index) {
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          item._showDetails = false
        }
      })
      // eslint-disable-next-line no-underscore-dangle
      this.tableDataSolicitudesBeneficio[index]._showDetails = !this.tableDataSolicitudesBeneficio[index]._showDetails
    },
    changeItemIndex({ detalleSolicitud }) {
      const objIndex = this.tableDataSolicitudesBeneficio.findIndex((obj => obj.id === detalleSolicitud.id))
      if (objIndex !== -1) {
        this.tableDataSolicitudesBeneficio[objIndex].solicitudEstado = { ...detalleSolicitud.solicitudEstado }
        this.tableDataSolicitudesBeneficio[objIndex].motivoRechazo = detalleSolicitud.motivoRechazo
      } else {
        console.error('Objeto ,objIndex, no encontrado', objIndex)
      }
    },
    detalleFechaEstado(item) {
      let detalle = ''
      if (item.solicitudEstado.codigo === '005') {
        // 005 = entregada
        detalle += this.formatDateTable(item.fechaEntregado, 'DD/MM/YYYY')
      }
      return detalle
    },
    buildNombre(item) {
      return `${item.apellido}, ${item.nombre}`
    },
    onSubmit() {
      if (!this.isBusyBuscando) {
        this.validationFormUsuario()
      }
    },
    getComprobante(idSolicitud) {
      this.isBusyReporte = true
      return reporteResource()
        .getComprobante(idSolicitud)
        .then(resultsReporte => {
          const date = new Date()
          const fileDate = this.formatDateTime(date, 'DD_MM_YYYY_HH_mm_ssZZ', 'DD-MM-YYYY-HH_mm_ssZZ')
          const blob = new Blob([resultsReporte], { type: 'application/pdf;charset=utf-8' })
          saveAs(blob, `comprobante_${fileDate}.PDF`)
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isBusyReporte = false
        })
    },
    consultarSolicitudes() {
      this.currentPage = 1
      this.pageBeneficio.page = 0
      this.getSolicitudesFilter()
    },
    getSolicitudesFilter() {
      this.isBusyBuscando = true
      this.tableDataSolicitudesBeneficio = []
      this.consultaBeneSolicitudFiltroDto.pageNumber = this.pageBeneficio.page
      this.consultaBeneSolicitudFiltroDto.pageSize = this.pageBeneficio.size
      this.consultaBeneSolicitudFiltroDto.sort = 'fechaSolicitud'
      this.consultaBeneSolicitudFiltroDto.order = 'desc'
      this.consultaBeneSolicitudFiltroDto.solicitanteCuil = this.afiliadoDto.cuil
      consultaSolicitudResource().findSolicitudesFilter(this.consultaBeneSolicitudFiltroDto)
        .then(resultFindSolicitudesFilter => {
          this.tableDataSolicitudesBeneficio = resultFindSolicitudesFilter.content
          this.pageTotalElements = resultFindSolicitudesFilter.totalElements
          this.pageNumber = resultFindSolicitudesFilter.number
          this.pageSize = resultFindSolicitudesFilter.size
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataSolicitudesBeneficio = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    changePaginate(item) {
      this.pageBeneficio.page = item - 1
      this.isBusyBuscando = true
      this.getSolicitudesFilter()
    },
    validationFormUsuario() {
      this.$refs.formValidationConsultaBeneficio.validate().then(success => {
        if (success) {
          this.consultarSolicitudes()
        }
      })
    },
  },
}
</script>
<style>
.mycontainer {
  text-align: center;
  padding-top: 28px;
}
</style>
