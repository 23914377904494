<template>
  <div>
    <b-overlay
      :show="isWorking"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div class="mt-2">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
        </div>
      </template>
      <b-card>
        <h4>
          {{ title }}
        </h4>
        <b-row>
          <b-col md="3">
            <p>
              Afiliado N°: <b>{{ afiliadoDto.numeroDocumento }}</b>
            </p>
          </b-col>
          <b-col md="3">
            <p>
              C.U.I.L.: <b>{{ formatCuit(afiliadoDto.cuil) }}</b>
            </p>
          </b-col>
          <b-col md="5">
            <p>
              Nombre: <b>{{ afiliadoDto.apellidoNombreCustom }}</b>
            </p>
          </b-col>
          <b-col
            v-if="!isEditable"
            md="1"
          >
            <div class="align-self-end ml-auto">
              <b-col md="12">
                <b-button
                  v-if="permisosUpd.includes(nameModulo)"
                  id="btnEditAfiliado"
                  v-b-tooltip.hover
                  variant="light"
                  title="Editar afiliado"
                  size="sm"
                  @click="editAfiliado()"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </b-col>
            </div>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col md="12">
            <b-tabs
              content-class="mt-3"
              class="border"
            >
              <b-tab title="General">
                <div class="mb-1 ml-1 mr-1">
                  <datos-personales
                    v-if="!isWorking"
                    :id-afiliado="idAfiliado"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :afiliado-dto="afiliadoDto"
                    :datos-personales="datosPersonales"
                  />
                </div>
              </b-tab>
              <b-tab title="Datos Laborales">
                <div class="mb-1 ml-1 mr-1">
                  <datos-laborales
                    v-if="!isWorking"
                    :id-afiliado="idAfiliado"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :afiliado-dto="afiliadoDto"
                  />
                </div>
              </b-tab>
              <b-tab title="Datos de Contacto">
                <div class="mb-1 ml-1 mr-1">
                  <datos-contacto
                    v-if="!isWorking"
                    :id-afiliado="idAfiliado"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :afiliado-dto="afiliadoDto"
                    :datos-contacto="DatosContacto"
                  />
                </div>
              </b-tab>
              <b-tab title="Grupo Familiar">
                <div class="mb-1 ml-1 mr-1">
                  <grupo-familiar
                    v-if="!isWorking"
                    :id-afiliado="idAfiliado"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :afiliado-dto="afiliadoDto"
                  />
                </div>
              </b-tab>
              <b-tab title="Documentación">
                <div class="mb-1 ml-1 mr-1">
                  <documentacion-afiliado
                    v-if="!isWorking"
                    :id-afiliado="idAfiliado"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :afiliado-dto="afiliadoDto"
                  />
                </div>
              </b-tab>
              <b-tab title="Beneficios">
                <div class="mb-1 ml-1 mr-1">
                  <beneficio-lista
                    v-if="!isWorking"
                    :id-afiliado="idAfiliado"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :afiliado-dto="afiliadoDto"
                  />
                </div>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { afiliadoResource } from '@/services/afiliadoResource'
import DatosPersonales from './DatosPersonales.vue'
import DatosLaborales from './DatosLaborales.vue'
import DatosContacto from './DatosContacto.vue'
import GrupoFamiliar from './GrupoFamiliar.vue'
import DocumentacionAfiliado from './DocumentacionAfiliado.vue'
import BeneficioLista from './BeneficioLista.vue'

export default {
  components: {
    DatosPersonales,
    DatosContacto,
    DatosLaborales,
    GrupoFamiliar,
    DocumentacionAfiliado,
    BeneficioLista,
  },
  props: {
    idAfiliado: {
      type: String,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nameModulo: 'ABM_AFILIADOS',
      title: '',
      isWorking: false,
      editar: false,
      afiliadoDto: {},
      datosPersonales: {
        id: null,
        cuil: null,
        documentoTipo: {
          id: null,
          nombre: null,
        },
        numeroDocumento: null,
        apellido: null,
        nombre: null,
        nombreApellidoCustom: null,
        fechaNacimiento: null,
        estadoCivil: {
          id: null,
          nombre: null,
        },
        genero: {
          id: null,
          nombre: null,
        },
        categoriaSindical: {
          id: null,
          nombre: null,
        },
        cbu: null,
        afiliacionEstado: {
          id: null,
          nombre: null,
        },
        fechaAlta: null,
        motivoBaja: {
          id: null,
          nombre: null,
        },
        delegacion: {
          id: null,
          nombre: null,
        },
        cuitEmpleador: null,
        razonSocial: null,
        empleadorCategoria: {
          id: null,
          nombre: null,
        },
        empleadorEstado: {
          id: null,
          nombre: null,
        },
        etapaCompletadaDatosPersonales: false,
      },
      DatosLaborales: {
        cuitEmpleador: null,
        razonSocial: null,
        empleadorCategoria: {
          id: null,
          nombre: null,
        },
        empleadorEstado: {
          id: null,
          nombre: null,
        },
      },
      DatosContacto: {
        id: null,
        personaContacto: null,
        telefono: null,
        email: null,
        domicilioCalle: null,
        domicilioNumero: null,
        domicilioOficinaDepto: null,
        codigoPostal: null,
        localidad: null,
        provincia: null,
        anotaciones: null,
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.5',
        blur: '5px',
        message: '',
      },
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
  },
  created() {
    this.setPageTitle()
    this.preloadedRoles = this.$store.getters.getRoles
    this.findAfiliado(this.idAfiliado)
  },
  methods: {
    setPageTitle() {
      if (this.openMode === 'edit') {
        this.title = 'Edición Afiliado'
      } else if (this.openMode === 'view') {
        this.title = 'Detalle Afiliado'
      } else {
        this.title = 'Nuevo Afiliado'
      }
    },
    formatCuit(cuit) {
      if (cuit && cuit.length === 11) {
        return `${cuit.substring(0, 2)}-${cuit.substring(2, 10)}-${cuit.substring(10)}`
      }
      return cuit
    },
    editAfiliado() {
      if (this.editar) {
        this.editar = false
        this.title = 'Detalle Afiliado'
      } else {
        this.editar = true
        this.title = 'Edición Afiliado'
      }
    },
    findAfiliado(idAfiliado) {
      this.isWorking = true
      this.overlayConfig.message = 'Recuperando datos de usuario'
      if (idAfiliado !== undefined) {
        afiliadoResource().findAfiliadoById(idAfiliado)
          .then(resultsFindByidAfiliado => {
            this.afiliadoDto = { ...resultsFindByidAfiliado }
          }).catch(error => {
            console.error(error)
            this.$router.push('/afiliados')
          })
          .finally(() => {
            this.isWorking = false
          })
      } else {
        this.isWorking = false
      }
    },
  },
}
</script>
